var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":{
    name: 'zone-overview',
    params: {
      zone: _vm.zone.id,
      site: _vm.zone.site_id
    }
  }}},[_vm._v(" "+_vm._s(_vm.zone.name)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }