<template>
<div>
  <level :title="`Latest ${Naming.Job} #${job.number}`">
    <div slot="right">
      <action-button class="is-hidden-touch" :working="downloading" @click="downloadReport" v-if="job.completed_at" left-icon="download">Report</action-button>
    </div>
  </level>

  <div class="columns">
    <div class="column">
      <p>
        <strong>Status: </strong> <current-status :status="job.current_status"/><br>
        <strong>Started:</strong> {{ startedAt }}<br>
        <strong>Completed:</strong> {{ completedAt }}<br>
        <strong>{{ Naming.Assets }} Inspected:</strong> {{ inspections.length }}<br>
        <strong>Defective {{ Naming.Assets }}:</strong> {{ failedInspections.length }}<br>
        <strong>Ok {{ Naming.Assets }}:</strong> {{ passedInspections.length }}<br>
        <strong>Failed {{ Naming.Checks }}:</strong> {{ failedChecks.length }}<br>
        <strong>Ok {{ Naming.Checks }}:</strong> {{ passedChecks.length }}<br>
      </p>
    </div>
    <div class="column">
      <p>
        <strong>Failed {{ Naming.Inspections }}</strong>
      </p>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ Naming.Asset }}</th>
            <th>Risk</th>
            <th>Date</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="inspection in failedInspections" :key="inspection.id">
            <td>#{{ inspection.id }}</td>
            <td>{{ inspection.asset.code }}</td>
            <td>{{ inspection.asset.risk.level }}</td>
            <td>{{ inspection.failed_at | date('L') }}</td>
            <td>
              <icon icon="comment"/>
              <span>{{ inspection.comments.length }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <p class="has-text-centered" v-if="!failedInspections.length">Good news, all {{ Naming.Assets.toLowerCase() }} have passed.</p>
    </div>
  </div>
</div>
</template>

<script>
import Job from '@/models/Job'
import { flatMap, find } from 'lodash'
import download from 'downloadjs'
import { job as backend } from '@/api'

export default {

  props: {
    job: {
      type: Object,
      default: () => (new Job)
    }
  },

  data: () => ({
    downloading: false
  }),

  methods: {
    downloadReport() {
      this.downloading = true
      backend.downloadReport(this.job.id, ({data}) => {
        this.downloading = false
        download(data, `#${this.job.number}-${this.job.site.name}.pdf`, 'application/pdf')
      }, error => {
        this.$whoops()
        this.downloading = false
      })
    }
  },

  computed: {
    inspections() {
      return this.job.inspections
    },
    failedInspections() {
      return this.inspections.filter(inspection => inspection.failed_at)
    },
    passedInspections() {
      return this.inspections.filter(inspection => inspection.passed_at)
    },
    answers() {
      return flatMap(this.inspections, inspection => inspection.answers)
    },
    failedChecks() {
      return this.answers.filter(answer => answer.answer === 0)
    },
    passedChecks() {
      return this.answers.filter(answer => answer.answer === 1)
    },
    notApplicableAnswers() {
      return this.answers.filter(answer => answer.answer === -1)
    },
    startedAt() {
      let status = find(this.job.statuses, status => status.value === 'in progress')
      return (status) ? moment(status.created_at).format('Y-MM-DD') : '--'
    },
    completedAt() {
      return (this.job.completed_at) ? moment(this.job.completed_at).format('Y-MM-DD') : '--'
    }
  }

}
</script>
