<template>
  <div class="is-flex flex-column align-center p-2">
    <div class="grid has-text-centered gap-1">
      <p>
        <icon v-if="icon"
          class="has-text-cool-blue-text is-large"
          :icon="fullyQualifiedIcon"
          v-bind="{ iconType }"/>
      </p>
      <div class="meta" v-if="title || description">
        <p v-if="title" class="is-size-5 ha-text-weight-medium mb">{{ title }}</p>
        <p v-if="description" class="has-text-grey">{{ description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    icon: {
      type: String,
      default: 'circle-notch'
    },
    iconType: {
      type: String,
      default: 'fas'
    },
    title: String,
    description: String,
  },

  computed: {
    fullyQualifiedIcon() {
      return `${this.icon} 2x`
    },
  }

}
</script>
