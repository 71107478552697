<template>
<router-link 
  :to="{
    name: 'zone-overview',
    params: {
      zone: zone.id,
      site: zone.site_id
    }
  }">
  {{ zone.name }}
</router-link>  
</template>

<script>
export default {
  props: {
    zone: {
      type: Object,
      default: () => ({
        site_id: '',
        id: ''
      })
    }
  }
}
</script>
