<template>
<span>
  <span 
    v-if="hasSubassets" 
    class="component-tag parent has-tooltip-left has-tooltip-multiline"
    :data-tooltip="`Parent ${Naming.Asset}. ${Naming.Assets} can be assigned to a parent ${Naming.Asset.toLowerCase()} as an ${Naming.Asset.toLowerCase()} component. This parent ${Naming.Asset.toLowerCase()} has (${subassetsCount}) component ${Naming.Assets.toLowerCase()}.`">
    <icon icon="sitemap"/>
    <span class="letter">Parent</span>
  </span>
  <span 
    v-if="hasParentAsset" 
    class="component-tag subasset has-tooltip-left has-tooltip-multiline"
    :data-tooltip="`${Naming.Asset} Component. ${Naming.Assets} can be assigned to a parent ${Naming.Asset.toLowerCase()} as an ${Naming.Asset.toLowerCase()} component. This ${Naming.Asset.toLowerCase()} is a component of ${asset.parent_asset.code}.`">
    <icon icon="sitemap"/>
    <span class="letter">Component</span>
  </span>
</span>
</template>
<script>
export default {
  props: {
    asset: {
      type: Object,
      default: () => {{}},
    },
  },
  computed: {
    hasParentAsset(){
      return this.asset.asset_id ? true : false
    },
    hasSubassets() {
      return this.asset?.sub_assets?.length > 0
    },
    subassetsCount(){
      return this.asset.sub_assets ? this.asset.sub_assets.length : 0
    }    
  }
};
</script>
