<template>
  <div class="is-flex align-center">
    <avatar
      :size="36"
      :path="user.picture_url"
      :name="user.full_name"/>
    <div class="is-flex flex-column ml-1">
      <span>
        <span class="has-text-weight-bold">{{ user.full_name }}</span>
        <span v-if="admin" class="ml has-text-weight-medium tracking-wide has-text-primary is-size-7 is-uppercase">Admin</span>
      </span>
      <span class="is-size-7 has-text-cool-blue-text">{{ user.email }}</span>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    user: {
      type: Object,
      required: true,
    },

    admin: {
      type: Boolean,
      default: false,
    }
  }

}
</script>
