var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":{
    name: 'job-handler',
    params: {
      site: _vm.job.site_id,
      job: _vm.job.id,
    }
  }}},[(_vm.$slots.default)?_vm._t("default"):_c('span',[_vm._v(_vm._s(`#${_vm.job.number}`))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }