<template>
  <div class="is-flex align-center">
    <avatar
      :size="36"
      :path="client.logo_url"
      :name="client.legal_name"/>
    <div class="is-flex flex-column ml-1">
      <span class="has-text-weight-bold">{{ client.legal_name }}</span>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    client: {
      type: Object,
      required: true,
    },
  }

}
</script>
