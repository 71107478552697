<template>
  <div class="has-text-centered">
    <croppa
      :initial-image="initialImage"
      :width="width"
      :height="height"
      canvas-color="#ffffff"
      v-model="croppa"
      v-closable="{
        exclude: ['add_logo_button', 'zoom_slider', 'upload_button'],
        handler: 'onClose'
      }"
      @new-image-drawn="imageSelected = true"
      @image-remove="removeImage"
    />
    <div class="is-flex align-center">
      <input
      @input="Number(
        updateZoom($event.target.value)
      )"
      :value="zoom"
      class="slider is-fullwidth is-info is-circle is-medium"
      step="1"
      min="0"
      max="100"
      type="range"
      ref="zoom_slider"
    >
    <icon v-if="zoom !== 50" icon="times-circle" @click="zoom = 50"/>
    </div>
    <action-button
      :working="working"
      :disabled="working || !imageSelected"
      @click="uploadImage"
    >Upload</action-button>
  </div>
</template>

<script>
export default {

  data: () => ({
    imageSelected: false,
    croppingImage: false,
    croppa: {},
    loaded: false,
    zoom: 50,
  }),

  props: {
    initialImage: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 500
    },
    addButtonText: {
      type: String,
      default: "Add Image"
    },
    working: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateZoom(newZoom) {
      if (newZoom > this.zoom && newZoom < 100) {
        this.croppa.zoomIn()
      }
      if (newZoom < this.zoom && newZoom > 0) {
        this.croppa.zoomOut()
      }
      this.zoom = newZoom
    },
    removeImage() {
      this.imageSelected = false
      this.croppingImage = false
      this.zoom = 50
    },
    uploadImage() {
      this.working = true
      this.croppa.generateBlob((blob) => {
        this.$emit('upload', blob)
      }, 'image/jpeg', 0.8)
    },
    onClose() {
      if(!this.imageSelected) this.croppingImage = false
    },
    completed() {
      this.working = false
      this.imageSelected = false
      this.croppingImage = false
    }
  }
}
</script>
