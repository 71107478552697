<template>
  <article class="widget">
    <div v-if="title" class="widget-header" :class="{ collapsed }">
      <span class="widget-title">{{ title }}</span>
      <a v-if="collapseable" @click="toggleCollapsedState" class="widget-collapse">
        <icon :icon="collapsed ? 'chevron-down' : 'chevron-up'"/>
      </a>
    </div>
    <expand-collapse>
      <div v-show="!collapsed" class="widget-slot-container">
        <div class="widget-slot">
          <slot/>
        </div>
      </div>
    </expand-collapse>
  </article>
</template>

<script>

export default {

  props: {
    title: String,

    collapseable: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    identifier: null,
    collapsed: false,
  }),

  beforeMount() {
    this.identifier = this.getIdentifier()
    const widgetPreferences = this.readPreferences()
    if (widgetPreferences) {
      this.collapsed = widgetPreferences.collapsed
    }
  },

  methods: {
    getIdentifier() {
      const identifier = this.$route.fullPath + this.title
      let hash = 5381, length = identifier.length
      while (length) hash = (hash * 33) ^ identifier.charCodeAt(--length)

      return `widget_${hash >>> 0}`
    },

    readPreferences() {
      return JSON.parse(localStorage.getItem(this.identifier))
    },

    writePreferences() {
      localStorage.setItem(this.identifier, JSON.stringify({
        collapsed: this.collapsed
      }))
    },

    toggleCollapsedState() {
      this.collapsed = !this.collapsed
      this.writePreferences()
    }
  }

}
</script>
