<template>
<router-link
  :class="{
    'flex-link': avatar
  }"
  :to="{
    name: 'site-overview',
    params: {
      site: site.id
    }
  }">
  <avatar v-if="avatar" :path="site.logo_url" :name="site.name"/>
  <span v-if="!this.$slots.default" :class="{ 'name': avatar }">{{ site.name }}</span>
  <slot v-else/>
</router-link>  
</template>

<script>
export default {
  props: {
    site: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: Boolean,
      default: false
    }
  }
}
</script>

