<template>
  <div class="columns">
    <div class="column is-6 is-flex justify-between answer-item">
      <span v-if="editValue === false" @click="editValue = true" class="answer-text">{{ answer.value }}</span>
      <text-input
          v-if="editValue === true"
          required
          :placeholder="'Predefined Answer Value'"
          v-model="answer.value"
          class="is-marginless">
        <action-button
            v-if="answer.value"
            slot="right"
            class="is-success"
            @click="updateValue">
          <icon icon="check"/>
        </action-button>
      </text-input>
      <span>
        <action-button
            v-if="editValue === false"
            @click="editValue = true"
            class="answer-icon answer-edit is-small is-rounded mr-1">
          <icon icon="pen"/>
        </action-button>
        <action-button
            :working="working"
            class="answer-icon answer-delete is-small is-rounded"
            @click="deleteAnswer">
          <icon
              icon="trash"/>
        </action-button>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'predefined-answer-item',

  props: {
    answer: {
      type: Object,
      required: true
    },
  },

  data: () => ({
    working: false,
    editValue: false
  }),

  methods: {
    async deleteAnswer() {
      this.working = true
      await this.$store.dispatch('customField/deletePredefinedAnswer', this.answer.id).then(() => {
        this.$emit('answerChanged')
        this.working = false
      })
    },
    async updateValue() {
      this.editValue = false
      await this.$store.dispatch('customField/updatePredefinedAnswer', this.answer)
      this.$emit('answerChanged')
    }
  }

}
</script>