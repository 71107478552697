<template>
  <span v-if="checklistInspectionDate">
    <span class="inspection-checklist-name" v-if="checklistInspectionDate.next_inspection">
      {{ checklistInspectionDate.checklist.name }}
    </span><span v-else>--</span><br>
    <span class="no-wrap"
      v-if="checklistInspectionDate.next_inspection"
      :data-tooltip="isPast ? `Overdue for ${Naming.Inspection}` : ''"
      :class="{
        'has-text-danger': isPast,
        'tooltip': isPast}">
      <icon icon="clock" v-if="isPast"/>
      {{ checklistInspectionDate.next_inspection | date('L') }}
    </span>
  </span>
  <span v-else>--</span>
</template>
<script>

import moment from 'moment'
import _ from 'lodash'

export default {
  props: {
    checklistInspectionDates: {
      type: Array,
      default: () => {[]}
    },
  },

  computed: {
    sortedChecklistInspectionDates() {
      return _.sortBy(this.checklistInspectionDates, dates => dates.next_inspection)
    },

    checklistInspectionDate() {
      let checklistId = this.$route.query.checklist

      if (!checklistId) { 
        return _.first(this.sortedChecklistInspectionDates)
      }

      let checklistInspectionDates = _.filter(
        this.checklistInspectionDates,
        dates => +dates.checklist_id === +checklistId
      )
      return _.first(checklistInspectionDates)
    },

    isPast() {
      if (this.checklistInspectionDate === null) {
        return false
      }

      return moment(this.checklistInspectionDate.next_inspection).isBefore(moment())
    },
  },
}

</script>