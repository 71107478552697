<template>
<router-link 
  :to="{
    name: 'asset-create',
    params: {
      site: site,
      zone: zone,
    }
  }">
  <icon icon="plus"/>
  <span>New {{ Naming.Asset }}</span>
</router-link>  
</template>

<script>

export default {
  props: {
    site: Number,
    zone: Number,
  }
}
</script>
