<template>
    <text-input
        :error="$root.errors['dialing_code']|| $root.errors['phone_number']"
        @keydown="validatePhoneNumber"
        @paste="handlePaste"
        :value="phone.number"
        @input="updateNumber">
            <data-selector
                no-revert
                :classes="size"
                @input="updateCode"
                :value="phone.code"
                prompt-label="code"
                :items="dialingCodes"
                slot="left"/>Phone number
    </text-input>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  
  export default {
  
    props: {
      number: {
        type: String,
        default: ''
      },
      code: {
        type: String,
        default: ''
      },
      size: String,
      required: {
        type: Boolean,
        default: false
      }
    },
  
    data: () => ({
      phone: {
        code: '',
        number: ''
      }
    }),
  
    created() {
      this.phone.number = this.number
      this.phone.code = this.code
    },
  
    methods: {
      updateCode(value) {
        this.phone.code = value
        this.$emit('code', value)
      },
      updateNumber(value) {
        this.phone.number = value
        this.$emit('number', value)
      },
      handlePaste(event) {
      event.preventDefault();
      const pastedText = event.clipboardData.getData("text/plain");
      this.phone.number = pastedText.replace(/\D/g, "");
      return this.updateNumber(this.phone.number)
    },
    validatePhoneNumber(event) {
      const key = event.key;
      const regex = /^[\d-]$/;

      if (event.keyCode === 8) {
      return;
      }

      if (!regex.test(key)) {
      event.preventDefault();
      }
    }
    },
  
    computed: {
      ...mapGetters('list', [
        'dialingCodes'
      ])
    }
  
  }
  </script>