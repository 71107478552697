<template>
<router-link 
  :to="{
    name: 'job-handler',
    params: {
      site: job.site_id,
      job: job.id,
    }
  }">
  <slot v-if="$slots.default"/>
  <span v-else>{{ `#${job.number}` }}</span>
</router-link>  
</template>

<script>

export default {
  props: {
    job: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
