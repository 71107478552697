var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{class:{
    'flex-link': _vm.avatar
  },attrs:{"to":{
    name: 'user-overview',
    params: {
      user: _vm.user.id
    }
  }}},[_c('span',{class:{ 'name': _vm.avatar }},[_vm._v(_vm._s(_vm.user.full_name))]),(_vm.avatar && _vm.user.profile)?_c('avatar',{attrs:{"path":_vm.user.profile.picture_url,"name":_vm.user.full_name}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }