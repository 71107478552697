<template>
  <abbr :[tooltipMethod]="formatted" :class="{ 'tooltip': useTooltip }">{{ fromNow }}</abbr>
</template>

<script>
export default {
  props: {
    time: String,
    useTooltip: {
      type: Boolean,
      default: false,
    },
    fullFormat: {
      type: String,
      default: 'D MMMM YYYY [at] HH:mm'
    },
    interval: {
      type: Number,
      default: 5000,
    }
  },

  data: () => ({
    fromNow: null,
  }),

  computed: {
    formatted() {
      return this.$options.filters.asCalendar(this.time, this.fullFormat)
    },

    tooltipMethod() {
      return this.useTooltip ? 'data-tooltip' : 'title'
    }
  },

  created() {
    this.getFromNow()
    setInterval(this.getFromNow, this.interval)
  },

  destroyed() {
    clearInterval(this.getFromNow)
  },

  methods: {
    getFromNow() {
      this.fromNow = moment(this.time).fromNow()
    }
  }
}
</script>
