<template>
  <div class="is-flex align-center">
    <div class="is-flex flex-column ml-1">
      <span class="has-text-weight-bold">{{ risk.level }}</span>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    risk: {
      type: Object,
      required: true,
    },
  }

}
</script>
