var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":{
    name: 'asset-overview',
    params: {
      asset: _vm.asset.id,
      zone: _vm.asset.zone_id,
      site: _vm.siteId
    }
  }}},[(!_vm.$slots.default)?_c('span',[(_vm.asset.code)?_c('span',[_vm._v(_vm._s(_vm.asset.code))]):_vm._e(),(_vm.asset.barcode && !_vm.asset.code)?_c('span',[_vm._v(_vm._s(_vm.asset.barcode.data))]):_vm._e()]):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }