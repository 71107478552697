<template>
  <loader v-bind="{ loading }">
    <article class="sortable-selector">
      <notification class="is-cool-blue has-text-centered p-2 is-marginless" v-if="!hasCustomFields">
        <p class="has-text-weight-bold">No {{ Naming.CustomFields }} Created</p>
        <p>You haven’t created any {{ Naming.CustomFields.toLowerCase() }} yet.</p>
      </notification>
      <draggable
          handle="#handle"
          :animation="200"
          v-model="customFields"
          group="customFields">
        <transition-group>
          <custom-fields-draggable-item
              v-for="(customField, index) in customFields"
              :key="customField.id"
              :custom-field="customField"
              :index="index"
              @refreshFields="refreshCustomFields"
          />
        </transition-group>
      </draggable>
      <div class="is-flex justify-end mtb-1" v-if="$root.hasAbility('reorder-custom-fields', 'App\\Models\\CustomField')">
        <action-button
            @click="saveCustomFieldOrder"
            :disabled="!hasCustomFields"
            class="is-success is-rounded is-inverted is-fullwidth"
            left-icon="save"
        >Save Order
        </action-button>
      </div>
    </article>
  </loader>
</template>
<script>

export default {

  name: 'draggable-box',

  props: {
    customFields: {
      type: Array,
    }
  },

  data: () => ({
    loading: false
  }),

  computed: {
    hasCustomFields() {
      return this.customFields?.length > 0;
    }
  },

  methods: {
    async saveCustomFieldOrder() {
      let payload = {
        custom_fields: this.customFields
      }
      this.loading = true
      await this.$store.dispatch('customField/updateCustomFieldOrder', payload).then(() => {
        this.refreshCustomFields()
        this.loading = false
      })
    },
    refreshCustomFields() {
      this.$emit('refreshParent')
    },
  }
}
</script>