var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{class:{
    'flex-link': _vm.avatar
  },attrs:{"to":{
    name: 'client-overview',
    params: {
      client: _vm.client.id
    }
  }}},[(_vm.avatar)?_c('avatar',{attrs:{"path":_vm.client.logo_url,"name":_vm.client.legal_name}}):_vm._e(),_c('span',{class:{ 'name': _vm.avatar }},[_vm._v(_vm._s(_vm.client.legal_name))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }