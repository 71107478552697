<template>
  <div class="is-flex align-center">
    <avatar
      :size="36"
      :path="site.logo_url"
      :name="site.name"/>
    <div class="is-flex flex-column ml-1">
      <span class="has-text-weight-bold">{{ site.name }}</span>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    site: {
      type: Object,
      required: true,
    },
  }

}
</script>
