<template>
  <span v-if=statusValue class="status" :class="statusClass">
    <template>{{ statusValue ?? '--' | ucwords }}</template>
    <template v-if="isOverdue">, past due</template>
  </span>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    status: {
      type: Object,
      default: null
    },
    due: String,
    latestStatus: {
      type: String,
      default: null
    },
  },

  data: () => ({
    bad: [
      'failing',
      'failed',
      'defective',
      'critical'
    ],
    good: [
      'passing',
      'passed',
      'ok'
    ],
    info: [
      'open',
      'paused'
    ],
    link: [
      'in progress'
    ],
  }),

  computed: {
    statusValue(){
      return this.latestStatus ?? this.status.value
    },
    isOverdue() {
      if (!this.due || (this.statusValue && !['in progress', 'open'].includes(this.statusValue))) return false
      const dueBy = moment(this.due).endOf('day')
      return moment().isAfter(dueBy)
    },

    statusClass() {
      if (!this.statusValue) return

      let hasBad = this.bad.filter(bad => bad === this.statusValue)
      if (hasBad.length > 0 || this.isOverdue) {
        return 'failed'
      }

      let hasGood = this.good.filter(good => good === this.statusValue)
      if (hasGood.length > 0) {
        return 'success'
      }

      let hasInfo = this.info.filter(info => info === this.statusValue)
      if (hasInfo.length > 0) {
        return 'info'
      }

      let hasLink = this.link.filter(link => link === this.statusValue)
      if (hasLink.length > 0) {
        return 'link'
      }

      return 'default'
    }
  }
}
</script>
