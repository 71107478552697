<template>
<router-link
  :class="{
    'flex-link': avatar
  }"
  :to="{
    name: 'client-overview',
    params: {
      client: client.id
    }
  }">
  <avatar v-if="avatar" :path="client.logo_url" :name="client.legal_name"/>
  <span :class="{ 'name': avatar }">{{ client.legal_name }}</span>
</router-link>  
</template>

<script>
export default {
  props: {
    client: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: Boolean,
      default: false
    }
  }
}
</script>


