<template>
  <dropdown-wrapper position="bottom-end">
    <action-button
      has-dropdown
      slot="trigger"
      left-icon="plus-circle"
      class="is-primary is-rounded">
      <span class="is-hidden-mobile">New {{Naming.Job}}</span>
    </action-button>
    <dropdown-item has-link>
      <route exact name="job-create" :query="site ? { site } : undefined">
        <icon icon="hashtag"/>
        <span>Single {{Naming.Job}}</span>
      </route>
    </dropdown-item>
    <dropdown-item has-link>
      <route exact name="job-create-recurring" :query="site ? { site } : undefined"
        v-if="hasAbility('Billow\\Models\\RecurringJob', 'create-recurring-jobs')">
        <icon icon="redo-alt"/>
        <span>{{Naming.RecurringJob}}</span>
      </route>
    </dropdown-item>
  </dropdown-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  props: {
    site: Number | String,
  },

  computed: {
    ...mapGetters('auth', [
      'user'
    ])
  },

  methods: {
    hasAbility(entityType, name) {
      return this.user.abilities.filter(ability => ability.entity_type === entityType && ability.name === name).length > 0
    }
  },

}
</script>
