<template>
<span v-if="lastChecklistInspectionDate">
  <span class="inspection-checklist-name" v-if="lastChecklistInspectionDate.last_inspection">
    {{ lastChecklistInspectionDate.checklist.name }}
  </span>
  <span v-else>--</span>
  <br>
  <span class="no-wrap" v-if="lastChecklistInspectionDate.last_inspection">
    {{ lastChecklistInspectionDate.last_inspection | date('L') }}
  </span>
</span>
<span v-else>--</span>
</template>
<script>

export default {

 props: {
    checklistInspectionDates: {
      type: Array,
      default: () => {[]}
    },
  },

  computed: {
    sortedChecklistInspectionDates() {
      let filteredDates = _.filter(this.checklistInspectionDates, date => date.last_inspection !== null) 
      return _.sortBy(filteredDates, dates => dates.last_inspection).reverse()
    },

    lastChecklistInspectionDate() {
      let checklistId = this.$route.query.checklist
      let filteredDate = this.$route.query.last_inspection
      
      if (!checklistId && !filteredDate){
        return _.first(this.sortedChecklistInspectionDates)
      }
      
      if (checklistId) {
        let checklistInspectionDates = _.filter(
          this.checklistInspectionDates,
          dates => +dates.checklist_id === +checklistId
        )
        return _.first(checklistInspectionDates)
      }

      if (filteredDate) {
        let date = Date.parse(filteredDate.substr(-10,10) + " 23:59:59")
        let checklistInspectionDates = _.filter(
          this.sortedChecklistInspectionDates,
          dates => +Date.parse(dates.last_inspection) <= +date,
        )
        return _.first(checklistInspectionDates)
      }
      
    },
  }
}

</script>
