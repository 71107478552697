var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{class:{
    'flex-link': _vm.avatar
  },attrs:{"to":{
    name: 'site-overview',
    params: {
      site: _vm.site.id
    }
  }}},[(_vm.avatar)?_c('avatar',{attrs:{"path":_vm.site.logo_url,"name":_vm.site.name}}):_vm._e(),(!this.$slots.default)?_c('span',{class:{ 'name': _vm.avatar }},[_vm._v(_vm._s(_vm.site.name))]):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }