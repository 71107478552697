<template>
<img :src="path"/>  
</template>

<script>
export default {
  props: {
    path: {
      type: String
    }
  }
}
</script>
