<template>
  <router-link
    @click.native="$event.stopImmediatePropagation()"
    v-bind="{ exact, tag }"
    :to="toPayload"
    :target="newTab ? '_blank' : false">
    <slot/>
  </router-link>
</template>

<script>
export default {

  props: {
    name: String,
    to: String | Object,

    params: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    },
    exact: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'a',
    },
    newTab: {
      type: Boolean,
      default: false
    },
    retainQuery: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    toPayload() {
      return typeof this.to === 'object' ? this.to : {
        name: this.name || this.$route.name,
        params: this.params,
        query: this.retainQuery ? {...this.$route.query, ...this.query} : this.query,
        to: this.to,
      }
    }
  }

}
</script>
