<template>
<router-link
  :class="{
    'flex-link': avatar
  }"
  :to="{
    name: 'user-overview',
    params: {
      user: user.id
    }
  }">
  <span :class="{ 'name': avatar }">{{ user.full_name }}</span>
  <avatar v-if="avatar && user.profile" :path="user.profile.picture_url" :name="user.full_name"/>
</router-link>  
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: Boolean,
      default: false
    }
  }
}
</script>

