<template>
<router-link 
  :to="{
    name: 'asset-overview',
    params: {
      asset: asset.id,
      zone: asset.zone_id,
      site: siteId
    }
  }">
  <span v-if="!$slots.default">
    <span v-if="asset.code">{{ asset.code }}</span>
    <span v-if="asset.barcode && !asset.code">{{ asset.barcode.data }}</span>
  </span>
  <slot v-else></slot>
</router-link>  
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      default: () => ({})
    },
    siteId: Number|String
  }
}
</script>
