<template>
  <div class="column"
       :class="[
           (customField.input_type === 'datetime' && !editingSite) ? 'is-8' : 'is-4',
           editingSite? 'is-6' : 'is-4'
           ]">
    <!--Number-->
    <number-input
        right-icon="hashtag"
        @input="updateCustomField"
        :debounce="300"
        v-if="customField.input_type === 'integer'"
        v-model="valuePlaceholder"
        :placeholder="customField.placeholder"
        :precision="0"
        :error="$root.errors.count">
      {{ customField.label ? customField.label : 'Enter value' }}
    </number-input>

    <!--List-->
    <select-input
        @input="updateCustomField"
        v-if="customField.id && customField.input_type === 'list'"
        label-key="value"
        value-key="value"
        :items="customField.predefined_answers"
        v-model="valuePlaceholder">
      {{ customField.label ? customField.label : 'Enter value' }}
    </select-input>

    <!--Text-->
    <text-input
        right-icon="pen"
        @input="updateCustomField"
        v-if="customField.input_type === 'string'"
        :placeholder="customField.placeholder"
        v-model="valuePlaceholder"
        :debounce="300">
      {{ customField.label ? customField.label : 'Enter value' }}
    </text-input>

    <!--Boolean-->
    <switch-input
        :inner-label="booleanPlaceholder ? 'Yes' : 'No'"
        v-if="customField.input_type === 'boolean'"
        :description="customField.placeholder"
        classes="is-medium"
        :value="booleanPlaceholder"
        @input="toggleValue">
      {{ customField.label ? customField.label : 'Enter value' }}
    </switch-input>

    <!--Date-->
    <date-picker
        right-icon="calendar"
        @input="updateCustomField"
        v-if="customField.input_type === 'date'"
        :placeholder="customField.placeholder"
        alt-date-format="j F Y"
        v-model="valuePlaceholder">
      {{ customField.label ? customField.label : 'Enter value' }}
    </date-picker>

    <!--Date + Time-->
    <div v-if="customField.input_type === 'datetime'">
      <label class="label">
        {{ customField.label }}
      </label>
      <div class="columns">
        <div class="column is-6">
          <date-picker
              right-icon="calendar"
              @input="combineDateTime"
              :placeholder="customField.placeholder"
              v-model="dateValue">
          </date-picker>
        </div>
        <div class="column is-6">
          <date-picker
              @input="combineDateTime"
              right-icon="clock"
              :placeholder="customField.placeholder"
              time
              no-calendar
              alt-date-format="j F Y"
              v-model="timeValue">
          </date-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: 'custom-field-value-input',

  props: {
    customField: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    valuePlaceholder: '',
    booleanPlaceholder: true,
    dateValue: '',
    timeValue: '',
    datePlaceholder: ''
  }),

  watch: {
    'customField.input_type'() {
      this.valuePlaceholder = ''
      this.booleanPlaceholder = true
      this.dateValue = ''
      this.timeValue = ''
    },
    booleanPlaceholder(){
      this.booleanToString()
      this.updateCustomField()
    }
  },

  computed: {
    canSave() {
      return this.valuePlaceholder
    },
    stringToBoolean() {
      return this.customField.value === 'Yes'
    },
    editingSite(){
      return this.$route.name === 'site-edit'
    }
  },

  created() {
    this.datePlaceholder = this.customField.value
  },

  mounted() {
    this.setUpDataTypes()
  },

  methods: {
    updateCustomField() {
      this.$emit('valueEntered', { value: this.valuePlaceholder, customField: this.customField})
    },
    setUpDataTypes() {
      if (this.customField.value || this.datePlaceholder) {
        let type = this.customField.input_type
        type === ('string') ? this.valuePlaceholder = this.customField.value : ''
        type === ('list') ? this.valuePlaceholder = this.customField.value : ''
        type === ('date') ? this.valuePlaceholder = moment(this.datePlaceholder) : ''
        type === ('integer') ? this.valuePlaceholder = +this.customField.value : ''
        type === ('boolean') ? this.booleanPlaceholder = this.stringToBoolean : ''
        type === ('datetime') ? this.dateValue = moment(this.datePlaceholder.split(' ')[0]) : ''
        type === ('datetime') ? this.timeValue = this.datePlaceholder.split(' ')[1] : ''
      }
    },
    booleanToString() {
      this.booleanPlaceholder === true
          ? this.valuePlaceholder = 'Yes'
          : this.valuePlaceholder = 'No'
    },
    toggleValue() {
      this.booleanPlaceholder = !this.booleanPlaceholder
      this.booleanToString()
      this.updateCustomField()
    },
    combineDateTime() {
      this.valuePlaceholder = this.dateValue + " " + this.timeValue
      this.updateCustomField()
    }
  }
}

</script>