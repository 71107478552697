<template>
  <div class="selected-item has-text-grey-darker">
    <icon v-if="$root.hasAbility('reorder-custom-fields', 'App\\Models\\CustomField')"
        icon="sort" id="handle" class="has-text-grey"/>
    <div>
      <span class="mr-1">{{ index + 1 }}</span>
    </div>
    <div class="grid has-3-columns gap-1 flex-grow align-center ml-2">
      <div>
        {{ customField.label }}
      </div>
      <div>
        {{ customField.value }}
      </div>
    </div>
    <action-button
        @click="editField"
        class="answer-icon answer-edit is-small is-rounded mr-1"
        v-if="$root.hasAbility('update-custom-fields', 'App\\Models\\CustomField')">
      <icon icon="edit"/>
    </action-button>
    <action-button
        @click="deleteField"
        class="answer-icon answer-delete is-small is-rounded mr-1"
        v-if="$root.hasAbility('delete-custom-fields', 'App\\Models\\CustomField')">
      <icon icon="trash"/>
    </action-button>
  </div>
</template>

<script>

export default {
  name: 'draggable-item',

  props: {
    customField: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },

  methods: {
    editField() {
      if (this.$route.params.customFieldTemplate) {
        this.$router.push({
          name: 'template-custom-field-edit',
          params: {
            customFieldTemplate: this.$route.params.customFieldTemplate,
            customField: this.customField.id
          }
        })
      } else {
        this.$router.push({
          name: 'site-custom-field-edit',
          params: {
            site: this.$route.params.site,
            customField: this.customField.id
          }
        })
      }
    },
    async deleteField() {
      if (await this.$confirm({
        title: 'Delete Custom Field',
        message: 'Are you sure you want to delete this custom field?'
      })) {
        this.$store.dispatch('customField/deleteCustomField', this.customField.id).then(() => {
          this.$emit('refreshFields')
        })
      }
    },
  }
}
</script>
