<template>
  <article class="is-flex align-center">
    <checkbox-input
      class="is-marginless"
      classes="is-circle"
      :disabled="!loaded || updating || $root.hasAbility('manage-notifications', 'App\\User') === false"
      :label="false"
      :inner-label=this.Convert(channel.description)
      :value="checked"
      @input="updateValue"/>
    <transition name="fade">
      <span v-if="pulseVisible" class="ml has-text-success has-text-weight-bold is-size-7 is-uppercase">Saved</span>
    </transition>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'

export default {

  props: {
    channel: {
      type: Object,
      default: () => ({})
    },
    creating: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    checked: false,
    loaded: false,
    updating: false,
    pulseVisible: false
  }),

  mounted() {
    if (!this.isSubscribed) {
      this.checked = false
      this.loaded = true
      return
    } else {
      this.checked = true
      this.loaded = true
    }
  },

  methods: {
    updateValue() {
      this.checked = !this.checked
      this.$emit('onToggle', this.checked)
      if (!this.creating) {
        this.updating = true
        this.dispatchSubscription()
          .then(this.pulse)
          .catch(() => {
            this.checked = !this.checked
            this.$toast.error('Something went wrong.', 'The noification subscription could not be changed. Please try again or contact support.')
          })
          .finally(() => this.updating = false)
      }
    },

    dispatchSubscription() {
      return this.$store.dispatch(
        this.checked
          ? 'user/subscribeToChannel'
          : 'user/unsubscribeFromChannel',
        this.channel.id
      )
    },

    pulse() {
      this.pulseVisible = true
      setTimeout(() => this.pulseVisible = false, 2000)
    }
  },

  computed: {
    ...mapGetters('user', [
      'user'
    ]),
    isSubscribed() {
      return this.user.notification_subscriptions
        .filter(subscription => subscription.pivot.notification_type_id === this.channel.id).length > 0
    }
  }

}
</script>
