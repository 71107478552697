<template>
  <span class="status" :class="statusClass">{{ status }}</span>
</template>

<script>
import { sortBy, first } from 'lodash'

export default {
  props: {
    status: String,
    statusClass: {
      type: Array | Object | String,
      default: 'default',
    }
  },
}
</script>
