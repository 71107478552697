var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":{
    name: 'asset-create',
    params: {
      site: _vm.site,
      zone: _vm.zone,
    }
  }}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',[_vm._v("New "+_vm._s(_vm.Naming.Asset))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }