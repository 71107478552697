<template>
<div class="is-flex justify-between align-center">
  <div :class="leftClasses">
    <slot name="left"/>
  </div>
  <div :class="rightClasses">
    <slot name="right"/>
  </div>
</div>  
</template>
<script>
export default {
  props: {
    leftClasses: {
      type: Object|String|Array,
    },
    rightClasses: {
      type: Object|String|Array,
    }
  }
}
</script>