<template>
  <router-link
    :class="{ 'flex-link': avatar }"
    :to="{
      name: 'user-overview',
      params: { user: user.id }
    }">
    <avatar
      v-if="avatar && (user.profile || user.picture_url)"
      :size="avatarSize"
      :path="user.profile ? user.profile.picture_url : user.picture_url"
      :name="user.full_name"/>
    <span :class="{ 'name': avatar }">{{ user.full_name }}</span>
    <icon v-if="isPrimary" icon="award"/>
  </router-link>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    avatar: {
      type: Boolean,
      default: false
    },
    isPrimary: {
      type: Boolean,
      default: false
    },
    avatarSize: undefined|Number
  }
}
</script>
