<template>
  <component
    :is="hasRisk ? 'warning-triangle' : 'check-circle'"
    :class="classes"
    :data-tooltip="risk ? `Risk: ${risk.level}` : 'Risk Unknown'"/>
</template>

<script>
export default {
  props: {
    risk: Object,
  },
  computed: {
    hasRisk() {
      return !this.risk || this.risk.value !== 5
    },
    classes() {
      let riskColours = this.risk ? {
        'has-text-warning': [2, 3, 4].includes(this.risk.value),
        'has-text-danger': this.risk.value === 1
      } : 'has-text-grey-lighter'

      return [
        this.hasRisk ? riskColours : 'has-text-success',
        'has-tooltip-left'
      ]
    }
  }
}
</script>
