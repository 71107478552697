<template>
  <action-button class="is-small is-primary" :class="{ 'is-inverted': !isActive }" v-bind="{ disabled }">
    <icon class="pointer-events-none" :icon="isActive ? 'chevron-up' : 'ellipsis-v'"/>
  </action-button>
</template>

<script>
export default {

  props: {
    isActive: Boolean,
    disabled: Boolean
  }

}
</script>
