<template>
  <div class="columns is-flex align-end">
    <div class="column is-7">
      <text-input
          required
          :placeholder="'Predefined Answer Value'"
          v-model="predefinedAnswer.value">
        Enter A Predefined Answer To Add To List
        <action-button
            slot="right"
            :working="working"
            :disabled="!canSave"
            class="is-primary"
            @click="savePredefinedAnswer">
          <icon icon="plus"/>
          <span>Add Answer</span>
        </action-button>
      </text-input>
    </div>
    <div class="column">
    </div>
  </div>
</template>
<script>

export default {
  name: 'predefined-answer-create',

  data: () => ({
    working: false,
    predefinedAnswer: {
      custom_field_id: '',
      value: '',
    }
  }),

  computed: {
    canSave() {
      return this.predefinedAnswer.value.length > 0;
    }
  },

  created() {
    this.predefinedAnswer.custom_field_id = this.$route.params.customField
  },

  methods: {
    async savePredefinedAnswer() {
      this.working = true
      await this.$store.dispatch('customField/storePredefinedAnswer', this.predefinedAnswer).then(() => {
        this.$emit('answerSaved')
        this.predefinedAnswer.value = ''
        this.working = false
      })
    },
  }

}

</script>