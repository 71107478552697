<template>
  <section class="physical-address">
    <div :class="{ 'mb-1': address.country_name }">
      <h2 class="has-text-weight-light is-size-5 mb-1">{{ title }}</h2>
      <template v-if="search">
        <field-label class="has-text-info">Search for an Address</field-label>
        <vue-google-places @placechanged="setAddress">
          <text-input
            class="is-marginless"
            classes="is-rounded"
            left-icon="map-marker"
            :label="false"
          />
        </vue-google-places>
      </template>
    </div>

    <template v-if="address.country_name">
      <div class="grid has-4-columns gap-1">
        <text-input
          :error="error('building')"
          placeholder="(optional)"
          v-model="address.building">
          Apartment/Unit/Suite/Building
        </text-input>
        <text-input
          required
          :error="error('street')"
          placeholder="Street Address"
          v-model="address.street">
          Street Address
        </text-input>
        <text-input
          :error="error('suburb')"
          placeholder="(optional)"
          v-model="address.suburb">
          Suburb
        </text-input>
        <text-input
          required
          :error="error('post_code')"
          v-model="address.post_code">
          Postal/ZIP Code
        </text-input>
        <text-input
          :required="canEditCity"
          :disabled="!canEditCity"
          :error="error('city')"
          v-model="address.city">
          City
        </text-input>
        <text-input
          :disabled="!canEditRegion"
          :error="error('region')"
          v-model="address.region">
          Region
        </text-input>
        <text-input
          disabled
          :error="error('country_id')"
          :value="address.country_name">
          Country
        </text-input>
        <text-input
          disabled
          :value="address.timezone">
          Timezone
        </text-input>
      </div>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { GoogleAddress } from '@/utils/google-address'
import { GoogleTimezone } from '@/utils/google-timezone'

export default {

  props: {
    title: {
      type: String,
      default: 'Address'
    },
    addr: {
      type: Object,
      default: () => ({})
    },
    search: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    canEditCity: false,
    canEditRegion: false,
    address: {
      building: '',
      street: '',
      suburb: '',
      city: '',
      region: '',
      province: '',
      country_name: '',
      country_id: '',
      post_code: '',
      timezone: '',
      lat: '',
      lng: '',
    }
  }),

  mounted() {
    if (Object.keys(this.addr).length) {
      this.address = {
        ...this.address,
        ...this.addr,
        country_name: this.addr.country.name
      }
    }
  },

  methods: {
    emit() {
      this.$emit('input', this.address)
    },

    async setAddress(resultPayload) {
      let address = new GoogleAddress(resultPayload.place.address_components, this.countries)

      // Build up the address
      this.address.suburb = address.suburb
      this.address.city = address.city
      this.address.region = address.region
      this.address.province = address.province
      this.address.street = address.street
      this.address.post_code = address.postalCode
      this.address.country_name = address.countryLongName
      this.address.country_id = address.countryId

      if (!this.address.city) this.canEditCity = true
      if (!this.address.region) this.canEditRegion = true

      // Get the timezone
      let { latitude, longitude } = resultPayload
      let timezoneResponse = await new GoogleTimezone(latitude, longitude).getTimezone()
      this.address.lat = latitude
      this.address.lng = longitude
      this.address.timezone = timezoneResponse.data.timeZoneId
    }
  },

  computed: {
    ...mapState('list', ['countries']),
    error() {
      return key => this.$root.errors[`address.${key}`] || this.$root.errors[key]
    }
  },

  watch: {
    address: {
      deep: true,
      handler: 'emit'
    }
  }

}
</script>
