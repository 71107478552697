<template>
  <data-selector
    v-if="items.length"
    searchable
    @input="setCountry"
    label-key="name"
    value-key="id"
    v-bind="{ items, value, error, disabled, required }">
    {{ label }}
  </data-selector>
</template>

<script>
import { mapState } from 'vuex'

export default {

  props: {
    label: {
      type: String,
      default: 'Country'
    },
    value: {
      type: undefined | Number,
      required: true
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: Array
  },

  computed: mapState('list', {
    items: 'countries'
  }),

  created() {
    if (!this.items.length) {
      this.$store.dispatch('list/loadCountries')
    }
  },

  methods: {
    setCountry(key) {
      this.$emit('input', key)
    },

    setCountryByName(name) {
      let countryKey = this.items.find(country => country.name === name || country.native_name === name).id
      this.$emit('input', countryKey)
    }
  }

}
</script>
